/* eslint-disable @typescript-eslint/camelcase */
import { AppService } from './AbstractService';

export default class TransactionService extends AppService {
  public updateDepositRequest(data: { request_id: string; status: string }) {
    return this.makeRequest('PUT', '/transactions/deposit', data);
  }

  public updateWithdrawRequest(data: {
    request_id: string; status: string; transferType: string; bankTransfer: boolean;
  }) {
    return this.makeRequest('PUT', '/transactions/withdraw', data);
  }

  public getRequestSummary(type: string, range: string, search: string = '') {
    return this.makeRequest('GET', `/transactions/admin/${type}/summary?range=${range}&search=${search}`);
  }

  public getTransactionSummary(data: { range: string; user_id?: string; search?: string }) {
    return this.makeRequest('GET', `/transactions/summary?range=${data.range}&user_id=${data.user_id || ''}&search=${data.search || ''}`);
  }

  public getReportSummary(range: string) {
    return this.makeRequest('GET', `/transactions/report/summary?range=${range}`);
  }

  public depositByAdmin(data: {
    to_bank: string;
    to_account_number: string;
    amount: number;
    user_id: string;
    request_time: number;
  }) {
    return this.makeRequest('POST', '/transactions/admin-deposit', data);
  }

  public withdrawByAdmin(data: {
    withdrawAmount: number;
    user_id: string;
  }) {
    return this.makeRequest('POST', '/transactions/admin-withdraw', data);
  }

  public getSummaryByType(data: {
    range: string;
    type: string;
    search?: string;
    promotion_id?: string;
  }) {
    return this.makeRequest('GET', `/transactions/summary/${data.type}?range=${data.range}&search=${data.search || ''}&promotion_id=${data.promotion_id || ''}`);
  }

  public getPromotionTransactionSummary(range: string) {
    return this.makeRequest('GET', `/transactions/admin/promotion/summary?range=${range}`);
  }

  public deleteTransaction(transactionId: string) {
    return this.makeRequest('DELETE', `/transactions/${transactionId}`);
  }
}
