var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('h1',{staticClass:"mb-2 font-weight-regular"},[_vm._v("รายการโปรโมชั่น")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var token = ref.token;
return [_c('v-card',{attrs:{"color":"grey lighten-5","flat":""}},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":""}}),_c('v-card-text',[_c('base-data-table',{ref:"PROMOTION_TABLE",attrs:{"data-url":_vm.promotionListUrl,"request-header":{
                  'authorization': ("Bearer " + token)
                },"filters":{
                  paginate: true,
                },"headers":[
                  { text: 'รหัส', value: '_id' },
                  { text: 'ชื่อโปรโมชั่น', value: 'promotion_name' },
                  { text: 'สถานะ', value: 'status' },
                  { text: 'สร้างเมื่อ', value: 'created_at' },
                  { text: 'แก้ไขเมื่อ', value: 'updated_at' },
                  { text: 'ตัวเลือก', value: 'options' } ]},scopedSlots:_vm._u([{key:"options",fn:function(ref){
                var props = ref.props;
return [(props.username !== 'superadmin' && props.status === 'ACTIVATED')?_c('v-btn',{staticClass:"ma-1",attrs:{"depressed":""},on:{"click":function($event){return _vm.updatePromotionStatus(props._id, 'DEACTIVATED')}}},[_c('v-icon',{attrs:{"left":"","small":"","color":"red"}},[_vm._v(" fas fa-times ")]),_vm._v(" ระงับ ")],1):_vm._e(),(props.status === 'DEACTIVATED')?_c('v-btn',{staticClass:"ma-1",attrs:{"depressed":"","color":"green","dark":""},on:{"click":function($event){return _vm.updatePromotionStatus(props._id, 'ACTIVATED')}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" fas fa-check ")]),_vm._v(" เปิดใช้งาน ")],1):_vm._e()]}},{key:"created_at",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.formatDate(props.created_at))+" ")]}},{key:"updated_at",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.formatDate(props.updated_at))+" ")]}}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }