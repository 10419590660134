var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('h1',{staticClass:"mb-2 font-weight-regular",staticStyle:{"font-size":"2.4rem"}},[_vm._v(" รายการฝากประจำวันที่ "+_vm._s(_vm.dateText)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"dark":"","color":"green","flat":""}},[_c('div',{staticClass:"pa-5"},[_c('center',[_c('span',[_vm._v("ยอดฝากทั้งหมด")]),_c('h1',[_vm._v(" "+_vm._s(_vm._f("numberWithSeperator")(_vm.amount))+" ")]),_c('span',[_vm._v("บาท")])])],1)])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"dark":"","color":"primary","flat":""}},[_c('div',{staticClass:"pa-5"},[_c('center',[_c('span',[_vm._v("จำนวนรายการ")]),_c('h1',[_vm._v(" "+_vm._s(_vm.transactions)+" ")]),_c('span',[_vm._v("รายการ")])])],1)])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var token = ref.token;
return [_c('v-card',{attrs:{"color":"grey lighten-5","flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('base-date-range-search',{ref:"SEARCH",attrs:{"default-date":_vm.range.split(',')},on:{"search":_vm.search}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:300ms",value:(_vm.setSearchText),expression:"setSearchText",arg:"300ms"}],attrs:{"label":"กรุณากรอกข้อมูลเพื่อค้นหา","filled":"","rounded":"","dense":"","hint":"ค้นหาได้เฉพาะ เลขบัญชีผู้เล่น ชื่อบัญชี username","persistent-hint":""}})],1)])],1)],1),_c('v-card-text',[_c('base-data-table',{ref:"TRANSACTION_TABLE",attrs:{"data-url":_vm.transactionUrl,"request-header":{
                  'authorization': ("Bearer " + token)
                },"headers":[
                  { text: 'เวลาที่ทำรายการ', value: 'request_time' },
                  { text: 'username', value: 'user_id' },
                  { text: 'ชื่อบัญชี', value: 'account_name' },
                  { text: 'เลขที่บัญชี', value: 'account_number' },
                  { text: 'รับคำขอโดย', value: 'assisstant_id' },
                  { text: 'รหัสอ้างอิงคำขอ', value: 'request_id' },
                  { text: 'จำนวนเงิน', value: 'amount', dataType: 'number' },
                  { text: 'จำนวนเงินก่อนทำธุรกรรม', value: 'before', dataType: 'number' },
                  { text: 'จำนวนเงินหลังทำธุรกรรม', value: 'after', dataType: 'number' },
                  { text: 'สร้างเมื่อ', value: 'created_at' },
                  { text: 'แก้ไขเมื่อ', value: 'updated_at' } ],"filters":{
                  range: _vm.range,
                  type: 'DEPOSIT',
                  search: _vm.textSearch,
                }},scopedSlots:_vm._u([{key:"request_time",fn:function(ref){
                var request_id = ref.props.request_id;
return [_c('span',{staticClass:"green--text"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(request_id.request_time))+" ")])]}},{key:"user_id",fn:function(ref){
                var user_id = ref.props.user_id;
return [_c('v-btn',{attrs:{"v-if":user_id,"text":"","to":{ name: 'PlayerDetail', params: { id: user_id._id } }}},[_vm._v(" "+_vm._s(user_id.username)+" ")])]}},{key:"account_number",fn:function(ref){
                var user_detail = ref.props.user_detail;
return [_c('div',[(user_detail)?_c('span',[_vm._v(" "+_vm._s(user_detail.bank)+" "+_vm._s(user_detail.account_number)+" ")]):_c('span',[_vm._v(" - ")])])]}},{key:"account_name",fn:function(ref){
                var user_detail = ref.props.user_detail;
return [_c('div',[(user_detail)?_c('span',[_vm._v(" "+_vm._s(user_detail.account_name)+" ")]):_c('span',[_vm._v(" - ")])])]}},{key:"assisstant_id",fn:function(ref){
                var assisstant_id = ref.props.assisstant_id;
return [(!assisstant_id)?_c('span',[_vm._v(" ระบบอัติโนมัติ ")]):_c('span',[_vm._v(" "+_vm._s(assisstant_id.name)+" ")])]}},{key:"request_id",fn:function(ref){
                var ref_props = ref.props;
                var request_id = ref_props.request_id;
                var type = ref_props.type;
                var promotion = ref_props.promotion;
return [(request_id && type !== 'PROMOTION')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(request_id._id)+" ")])]}}],null,true)},[_c('h3',[_vm._v("รายละเอียดคำขอ")]),_c('span',[_vm._v("ธนาคาร: "+_vm._s(request_id.to_bank)+" "+_vm._s(request_id.to_account_number))]),_c('br'),_c('span',[_vm._v("จำนวนเงิน: "+_vm._s(request_id.amount))]),_c('br'),(request_id.request_type === 'DEPOSIT' && request_id.slip)?_c('img',{attrs:{"src":request_id.slip,"height":"540 * 1.6 px","width":"auto"}}):_vm._e()]):(type === 'PROMOTION')?_c('span',[_vm._v(" โปรโมชั่น "+_vm._s(promotion.promotion_name)+" ")]):(type === 'AFFILIATE')?_c('span',[_vm._v(" ให้เครดิตค่าแนะนำ ")]):_c('span',[_vm._v(" ดึงเครดิตกลับ ")])]}},{key:"created_at",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.formatDate(props.created_at))+" ")]}},{key:"updated_at",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.formatDate(props.updated_at))+" ")]}}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }