import {
  VuexModule,
  Module,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import Pusher from 'pusher-js';

const sound = require('@/assets/sound/notifysound.mp3');
const followNotifySound = require('@/assets/sound/follownotifysound.mp3');

@Module({ namespaced: true })
export default class Notification extends VuexModule {
  public withdrawRequestCreatedIds: Array<string> = [];

  public depositRequestCreatedIds: Array<string> = [];

  public followUpIds: Array<string> = [];

  @Mutation
  public updateNotifyState(payload: { type: string; data: Array<string> }) {
    if (payload.type === 'deposit') this.depositRequestCreatedIds = [...payload.data];
    if (payload.type === 'withdraw') this.withdrawRequestCreatedIds = [...payload.data];
    if (payload.type === 'followUp') this.followUpIds = payload.data;
  }

  @Action
  public async setNotificationDataToLocalStorage(payload: { type: string; data: string }) {
    console.log('new data coming');
    let data: string | null = await sessionStorage.getItem(payload.type);
    if (!data) {
      data = JSON.stringify([payload.data]);
    } else {
      const jsonData = JSON.parse(data);
      jsonData.push(payload.data);
      data = JSON.stringify(jsonData);
    }
    await sessionStorage.setItem(payload.type, data);
    this.context.commit('updateNotifyState', { type: payload.type, data: JSON.parse(data) });
  }

  @Action
  public subscribeNotification() {
    const pusher = new Pusher('1d28c69ee1bc8563c1c4', { cluster: 'ap1' });
    const channel = pusher.subscribe('notification');
    channel.bind('deposit-request-created', async (data: string) => {
      await this.context.dispatch('setNotificationDataToLocalStorage', { type: 'deposit', data });
      const notify = new Audio(sound);
      notify.play();
    });
    channel.bind('withdraw-request-created', async (data: string) => {
      await this.context.dispatch('setNotificationDataToLocalStorage', { type: 'withdraw', data });
      const notify = new Audio(sound);
      notify.play();
    });
    channel.bind('followup', async (data: string) => {
      await this.context.dispatch('setNotificationDataToLocalStorage', { type: 'followUp', data });
      const notify = new Audio(followNotifySound);
      notify.play();
    });
  }

  @Action
  public async removeRequestState(type: string) {
    await sessionStorage.removeItem(type);
    this.context.commit('updateNotifyState', { type, data: [] });
  }

  @Action
  public async initiateNotificationState() {
    const depositData = await sessionStorage.getItem('deposit');
    if (depositData) {
      this.context.commit('updateNotifyState', { type: 'deposit', data: JSON.parse(depositData) });
    }
    const withdrawData = await sessionStorage.getItem('withdraw');
    if (withdrawData) {
      this.context.commit('updateNotifyState', { type: 'withdraw', data: JSON.parse(withdrawData) });
    }
    const followUpData = await sessionStorage.getItem('followUp');
    if (followUpData) {
      this.context.commit('updateNotifyState', { type: 'followUp', data: JSON.parse(followUpData) });
    }
  }
}
