










































































































































































































































































































































































import Auth from '@/connector/Auth.vue';
import Notification from '@/connector/Notification.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Auth,
    Notification,
  },
})
export default class DefaultLayout extends Vue {
  public isOpen: boolean = false;
}
