var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('h1',{staticClass:"mb-2 font-weight-regular",staticStyle:{"font-size":"2.4rem"}},[_vm._v(" สรุปยอดรายวัน "+_vm._s(_vm.dateText)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var token = ref.token;
return [_c('v-card',{attrs:{"color":"grey lighten-5","flat":""}},[_c('v-card-title',[_c('base-date-range-search',{ref:"SEARCH",attrs:{"default-date":_vm.range.split(',')},on:{"search":_vm.search}})],1),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":""}}),_c('v-card-text',[_c('base-data-table',{ref:"SUMMARY_TABLE",attrs:{"data-url":_vm.reportUrl,"request-header":{
                  'authorization': ("Bearer " + token)
                },"filters":{
                  paginate: true,
                  range: _vm.range,
                },"headers":[
                  { text: 'วันที่', value: 'date', width: '100%' },
                  { text: 'ผู้เล่นทั้งหมด', value: 'players' },
                  { text: 'สมัครใหม่วันนี้', value: 'today_new_player' },
                  {
                    text: 'สมัครใหม่และเติมเงิน',
                    value: 'player_created_deposit_sameday',
                  },
                  { text: 'ผู้เล่นเติมเงินวันนี้', value: 'today_deposit_player' },
                  { text: 'จำนวนรายการฝาก', value: 'today_deposit' },
                  { text: 'รวมยอดฝาก', value: 'today_deposit_amount', dataType: 'number' },
                  { text: 'จำนวนรายการถอน', value: 'today_withdraw' },
                  { text: 'รวมยอดถอน', value: 'today_withdraw_amount', dataType: 'number' },
                  { text: 'ฝาก-ถอน', value: 'diff', dataType: 'number' },
                  { text: 'จำนวนรายการโบนัส', value: 'today_bonus' },
                  { text: 'รวมยอดโบนัส', value: 'today_bonus_amount', dataType: 'number' } ]},scopedSlots:_vm._u([{key:"date",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.formatDate(props.date))+" ")]}},{key:"diff",fn:function(ref){
                var props = ref.props;
return [_c('span',{class:((_vm.getColor(props.today_deposit_amount, props.today_withdraw_amount)) + "--text\n                    ")},[_vm._v(" "+_vm._s(_vm._f("numberWithSeperator")((props.today_deposit_amount - props.today_withdraw_amount)))+" ")])]}},{key:"table-footer",fn:function(){return [_c('tr',[_c('td',{staticClass:"green white--text",attrs:{"colspan":"2"}},[_c('center',[_vm._v(" สรุป ")])],1),_c('td',[_vm._v(_vm._s(_vm.summary.total_new_player))]),_c('td',[_vm._v(_vm._s(_vm.summary.total_player_created_deposit_sameday))]),_c('td',[_vm._v(_vm._s(_vm.summary.total_deposit_player))]),_c('td',[_vm._v(_vm._s(_vm.summary.total_deposit))]),_c('td',[_vm._v(_vm._s(_vm._f("numberWithSeperator")(_vm.summary.total_deposit_amount)))]),_c('td',[_vm._v(_vm._s(_vm.summary.total_withdraw))]),_c('td',[_vm._v(_vm._s(_vm._f("numberWithSeperator")(_vm.summary.total_withdraw_amount)))]),_c('td',[_c('span',{class:((_vm.getColor(_vm.summary.total_deposit_amount, _vm.summary.total_withdraw_amount)) + "--text\n                        ")},[_vm._v(" "+_vm._s(_vm._f("numberWithSeperator")((_vm.summary.total_deposit_amount - _vm.summary.total_withdraw_amount)))+" ")])]),_c('td',[_vm._v(_vm._s(_vm.summary.total_bonus))]),_c('td',[_vm._v(_vm._s(_vm._f("numberWithSeperator")(_vm.summary.total_bonus_amount)))])])]},proxy:true}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }