

















































































































































































































/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable prefer-destructuring */
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';

@Component
export default class PromotionForm extends Vue {
  @Prop({ default: {} }) promotionData!: any;

  public promotionType: string = '';

  public promotionName: string = '';

  public promotionDetail: string = '';

  public turnover: number = 0;

  public startHour: string = '00';

  public startMinute: string = '00';

  public endHour: string = '00';

  public endMinute: string = '00';

  public giveAmount: number = 0;

  public giveType: string = '';

  public status: string = 'ACTIVATED';

  public minimumDeposit: number = 0;

  public limitPerDay: number = 0;

  public maximumGive: number = 0;

  public payback: number = 0;

  public exactAmount: number = 0;

  public maximumWithdraw: number = 0;

  // eslint-disable-next-line class-methods-use-this
  get hours() {
    return [...Array(24).keys()].map((value) => {
      if (value < 10) return `0${value}`;
      return value;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  get minutes() {
    return [...Array(60).keys()].map((value) => {
      if (value < 10) return `0${value}`;
      return value;
    });
  }

  getPromotionFormData() {
    return {
      promotion_type: this.promotionType,
      promotion_name: this.promotionName,
      promotion_detail: this.promotionDetail,
      turnover: Number(this.turnover),
      start_time: `${this.startHour}:${this.startMinute}`,
      end_time: `${this.endHour}:${this.endMinute}`,
      give_amount: Number(this.giveAmount),
      give_type: this.giveType,
      status: this.status,
      minimum_deposit: Number(this.minimumDeposit),
      limit_per_day: Number(this.limitPerDay),
      maximum_give: Number(this.maximumGive),
      payback: Number(this.payback),
      exact_amount: Number(this.exactAmount),
      maximum_withdraw: Number(this.maximumWithdraw),
    };
  }

  @Watch('promotionData')
  onPromotionDataRecive(data: any) {
    this.promotionType = data.promotion_type;
    this.promotionName = data.promotion_name;
    this.promotionDetail = data.promotion_detail;
    this.turnover = data.turnover;
    this.giveAmount = data.give_amount;
    this.giveType = data.give_type;
    this.status = data.status;
    this.minimumDeposit = data.minimum_deposit;
    this.limitPerDay = data.limit_per_day;
    this.maximumGive = data.maximum_give;
    this.payback = data.payback;
    const startTime = data.start_time.split(':');
    const endTime = data.end_time.split(':');
    this.startHour = startTime[0];
    this.startMinute = startTime[1];
    this.endHour = endTime[0];
    this.endMinute = endTime[1];
    this.exactAmount = data.exact_amount;
    this.maximumWithdraw = data.maximum_withdraw;
  }
}
