


























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';
import BaseDateRangeSearch from '@/components/BaseDateRangeSearch.vue';
import TransactionService from '@/services/TransactionService';
import PromotionService from '@/services/PromotionService';

const transactionService = new TransactionService();
const promotionService = new PromotionService();

@Component({
  components: {
    BaseDataTable,
    Auth,
    BaseDateRangeSearch,
  },
})
export default class BetList extends Vue {
  public loading: boolean = false;

  public range: string = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')].toString();

  public transactions: number = 0;

  public summary: number | string = 0;

  public promotions: Array<any> = [];

  public selectedPromotion: string = '';

  public summaryDetail: { [key: string]: any } = {};

  public summaryDetailLoading: boolean = false;

  // eslint-disable-next-line class-methods-use-this
  public formatDate(time: number) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }

  async search(data: any) {
    this.range = data;
  }

  @Watch('range', {
    immediate: true,
  })
  async onRangeChange() {
    await this.getPromotionSummary();
    if (this.selectedPromotion === '') {
      await this.getPromotionSummaryDetail();
    }
  }

  @Watch('selectedPromotion')
  async onSelectedPromotionChange(value: string) {
    if (value === '') {
      await this.getPromotionSummaryDetail();
    }
    await this.getPromotionSummary();
  }

  get dateText() {
    const date = this.range.split(',');
    return `${date[0]} - ${date[1]}`;
  }

  // eslint-disable-next-line class-methods-use-this
  get transactionUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/transactions`;
  }

  public async getPromotionSummary() {
    const { data } = await transactionService.getSummaryByType({
      range: this.range,
      type: 'promotion',
      // eslint-disable-next-line @typescript-eslint/camelcase
      promotion_id: this.selectedPromotion,
    });
    console.log('data', data);
    this.transactions = data.transactions;
    this.summary = Number(data.summary).toFixed(2);
  }

  public async getPromotionSummaryDetail() {
    this.summaryDetailLoading = true;
    const { data } = await transactionService.getPromotionTransactionSummary(this.range);
    const result: { [key: string]: any } = {};
    data.forEach((currentData: any) => {
      /* eslint-disable */
      (result[currentData.promotion.promotion_name] = result[currentData.promotion.promotion_name] || []).push(currentData);
    }, {});
    result.all = [...data]
    console.log(result);
    this.summaryDetail = result;
    this.summaryDetailLoading = false;
  }

  getLength(data: Array<any>): number {
    if (!data) return 0;
    return data.length;
  }

  getSum(data: Array<any>): number {
    if (!data) return 0;
    return data.reduce((acc, current) => acc += current.amount, 0);
  }

  // eslint-disable-next-line class-methods-use-this
  get promotionListUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/promotions`;
  }

  async created() {
    const { data } = await promotionService.getPromotions();
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.promotions = [{ _id: '', promotion_name: 'ทั้งหมด' }, ...data];
  }
}
