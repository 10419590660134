/* eslint-disable @typescript-eslint/camelcase */
import { AppService } from './AbstractService';

export default class PromotionService extends AppService {
  public updatePromotionStatus(promotionId: string, status: string) {
    return this.makeRequest('PUT', `/promotions/${promotionId}`, { status });
  }

  public getPromotions() {
    return this.makeRequest('GET', '/promotions');
  }

  public createPromotion(promotionData: any) {
    return this.makeRequest('POST', '/promotions', promotionData);
  }

  public getPromotionById(promotionId: string) {
    return this.makeRequest('GET', `/promotions/${promotionId}`);
  }

  public updatePromotion(promotionId: string, data: any) {
    return this.makeRequest('PUT', `/promotions/${promotionId}`, data);
  }

  public giveawayCredit(userId: string, amount: number, promotionId: string, notifyImage: string) {
    return this.makeRequest('POST', '/promotions/giveaway', {
      userId, amount, promotionId, notifyImage,
    });
  }

  public giveBonus(userId: string) {
    return this.makeRequest('POST', '/promotions/force-join', { userId, promotionId: '61884c85ecad100c08cece0c' });
  }

  public clearTurnover(turnoverId: string) {
    return this.makeRequest('PUT', `/promotions/turnover/${turnoverId}`);
  }
}
