







































































import { Component, Vue } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';
import BaseDateRangeSearch from '@/components/BaseDateRangeSearch.vue';

@Component({
  components: {
    BaseDataTable,
    Auth,
    BaseDateRangeSearch,
  },
})
export default class DepositRanking extends Vue {
  public loading: boolean = false;

  public range: string = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')].toString();

  public textSearch: string = '';

  async search(data: any) {
    this.range = data;
  }

  public setSearchText(value: string) {
    this.textSearch = value;
  }

  get dateText() {
    const date = this.range.split(',');
    return `${date[0]} - ${date[1]}`;
  }

  // eslint-disable-next-line class-methods-use-this
  get reportUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/transactions/deposit-ranking`;
  }

  // eslint-disable-next-line class-methods-use-this
  public getColor(a: number, b: number) {
    const sum = a - b;
    if (sum > 0) return 'green';
    return 'red';
  }

  // eslint-disable-next-line class-methods-use-this
  public formatDate(date: string) {
    return moment(date).format('DD/MM/YYYY');
  }
}
