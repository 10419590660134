




























































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';
import TransactionService from '@/services/TransactionService';
import BaseDateRangeSearch from '@/components/BaseDateRangeSearch.vue';
import BaseConfirmDialog from '@/components/BaseConfirmDialog.vue';

const transactionService = new TransactionService();

@Component({
  components: {
    BaseDataTable,
    BaseDateRangeSearch,
    Auth,
    BaseConfirmDialog,
  },
})
export default class Transaction extends Vue {
  public loading: boolean = false;

  public depositSummary: number = 0;

  public withdrawSummary: number = 0;

  public promotionSummary: number = 0;

  public transactions: number = 0;

  public textSearch: string = '';

  public userId: any = '';

  // eslint-disable-next-line max-len
  public range: any = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')].toString();

  async search(data: any) {
    this.range = data;
  }

  public setSearchText(value: string) {
    this.textSearch = value;
  }

  @Watch('textSearch')
  async onTextSearchChange() {
    await this.getTransactionSummary();
  }

  @Watch('range')
  async onRangeChange() {
    await this.getTransactionSummary();
  }

  // eslint-disable-next-line class-methods-use-this
  public formatDate(time: number) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }

  // eslint-disable-next-line class-methods-use-this
  get transactionUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/transactions`;
  }

  get dateText() {
    const date = this.range.split(',');
    return `${date[0]} - ${date[1]}`;
  }

  async getTransactionSummary() {
    try {
      const { data } = await transactionService.getTransactionSummary({
        range: this.range,
        // eslint-disable-next-line @typescript-eslint/camelcase
        search: this.textSearch,
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: this.userId,
      });
      this.depositSummary = data.depositSummary;
      this.withdrawSummary = data.withdrawSummary;
      this.promotionSummary = data.promotionSummary;
      this.transactions = data.length;
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  async deleteTransaction(transactionId: string) {
    try {
      const confirm = await (this.$refs.DELETE_TRANSACTION_CONFIRM_DIALOG as Vue & { open: (data: any) => boolean }).open({ title: 'ท่านต้องการลบรายการธุรกรรมดังกล่าวใช่หรือไม่' });
      if (!confirm) return;
      await transactionService.deleteTransaction(transactionId);
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
      (this.$refs.TRANSACTION_TABLE as Vue & { refreshData: () => void }).refreshData();
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  async created() {
    const { player, range } = this.$route.query;
    if (player) {
      this.userId = player;
    }
    if (range) {
      this.range = range;
    }
    await this.getTransactionSummary();
  }
}
