

































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import BaseDateRangeSearch from '@/components/BaseDateRangeSearch.vue';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';
import PlayerService from '@/services/PlayerService';
import TransactionService from '@/services/TransactionService';
import PromotionService from '@/services/PromotionService';

const transactionService = new TransactionService();
const promotionService = new PromotionService();
const playerService = new PlayerService();

@Component({
  components: {
    BaseDataTable,
    Auth,
    BaseDateRangeSearch,
  },
})
export default class UserDetail extends Vue {
  public loading: boolean = false;

  public profile: any = {};

  public stat: any = {};

  public balance: number = 0;

  public depositSummary: number = 0;

  public withdrawSummary: number = 0;

  public promotionSummary: number = 0;

  public transactions: number = 0;

  // eslint-disable-next-line max-len
  public range: string = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')].toString();

  async search(data: any) {
    this.range = data;
  }

  async created() {
    const playerId = this.$route.params.id;
    const { data: profile } = await playerService.getPlayerById(playerId);
    const { data: { result } } = await playerService.getPlayerBalance(profile.agent_api_username);
    const { data: stat } = await playerService.getPlayerStatById(playerId);
    await this.getTransactionSummary();
    this.profile = { ...profile };
    this.stat = { ...stat };
    this.balance = result.balance;
  }

  // eslint-disable-next-line class-methods-use-this
  public formatDate(time: number) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }

  get playerTurnoversUrl() {
    const playerId = this.$route.params.id;
    return `${process.env.VUE_APP_API_ENDPOINT}/promotions/turnovers/${playerId}`;
  }

  get playerAffiliateMember() {
    const playerId = this.$route.params.id;
    return `${process.env.VUE_APP_API_ENDPOINT}/users/affiliates/${playerId}`;
  }

  @Watch('range')
  async onRangeChange() {
    await this.getTransactionSummary();
  }

  // eslint-disable-next-line class-methods-use-this
  get transactionUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/transactions`;
  }

  // eslint-disable-next-line class-methods-use-this
  async clearTurnover(id: string) {
    try {
      this.loading = true;
      await promotionService.clearTurnover(id);
      this.loading = false;
      (this.$refs.TURNOVER_TABLE as Vue & { refreshData: () => void }).refreshData();
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  // eslint-disable-next-line @typescript-eslint/camelcase
  async getTransactionSummary() {
    try {
      const { data } = await transactionService.getTransactionSummary({
        range: this.range,
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: this.$route.params.id,
      });
      this.depositSummary = data.depositSummary;
      this.withdrawSummary = data.withdrawSummary;
      this.promotionSummary = data.promotionSummary;
      this.transactions = data.length;
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}
