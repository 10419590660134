































































































import { Component, Vue } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';
import UserService from '@/services/UserService';

const userService = new UserService();

@Component({
  components: {
    BaseDataTable,
    Auth,
  },
})
export default class DepositRequest extends Vue {
  public loading: boolean = false;

  // eslint-disable-next-line class-methods-use-this
  public formatDate(time: number) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }

  // eslint-disable-next-line class-methods-use-this
  get adminListUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/admins`;
  }

  async updateAdminStatus(id: string, status: string) {
    try {
      this.loading = true;
      await userService.updateAdmin(id, { status });
      this.loading = false;
      (this.$refs.ADMIN_TABLE as Vue & { refreshData: () => void }).refreshData();
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}
