/* eslint-disable @typescript-eslint/camelcase */
import { AppService } from './AbstractService';

export default class PlayerService extends AppService {
  public updatePlayer(
    id: string,
    updateData: {
      username?: string;
      password?: string;
      line_id?: string;
      bank?: string;
      status?: string;
      account_name?: string;
      account_number?: string;
      isAgent?: boolean;
      share_config?: any;
      followed?: boolean;
    },
  ) {
    return this.makeRequest('PUT', `/users/${id}`, updateData);
  }

  public getPlayerById(id: string) {
    return this.makeRequest('GET', `/users/${id}`);
  }

  public getPlayerStatById(id: string) {
    return this.makeRequest('GET', `/users/stat/${id}`);
  }

  public getPlayerTurnoverByPlayerId(id: string) {
    return this.makeRequest('GET', `/promotions/turnovers/${id}`);
  }

  public getPlayerBalance(username: string) {
    return this.makeRequest('GET', `/users/balance/${username}`);
  }

  public getAffilatePlayer(id: string) {
    return this.makeRequest('GET', `/users/affiliates/${id}`);
  }

  public getPlayerLoginHistory(
    search: {
      userId?: string;
      username?: string;
      accountName?: string;
      accountNumber?: string;
      range?: string;
    },
  ) {
    return this.makeRequest('GET', `/users/login-histories?userId=${search.userId || ''}&username=${search.username || ''}&account_name=${search.accountName || ''}&account_number=${search.accountNumber || ''}&range=${search.range || ''}`);
  }

  public getFollowUpPlayer() {
    return this.makeRequest('GET', '/users/followup');
  }

  public setBetConfig(data: number) {
    return this.makeRequest('POST', '/users/bet-config', { betConfig: data });
  }
}
